@import '../../../style/_var.scss';

#FAQ {
  padding: 0 20px;
  .faq_title {
    margin: 50px 0;
    .middle_header_img {
      width: 13%;
    }
  }
  .collapes_wrap {
    margin: 80px 0 80px;
    .faq_node {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 50px;
      cursor: pointer;
      border-radius: 6px;
      padding: 14px;
      margin-bottom: 15px;
      position: relative;
      background-color: rgba(255, 255, 255, 0.1);
      .Q {
        color: #e2e2e2;
        margin-right: 15px;
      }
      .q {
        width: 100%;
        .q_title {
          flex: 1;
          padding-right: 10px;
          font-size: 14px;
          color: #e2e2e2;
        }
        .arrow_down {
          width: 14px;
        }
      }
      .a {
        max-height: 0px;
        overflow: hidden;
        margin: 0 10px 0 25px;
        font-size: 14px;
        color: #e2e2e2;
        transition: all 0.3s ease-out;

        .icon {
          width: 20px;
          margin-right: 15px;
        }
      }

      &.active {
        .a {
          padding-top: 15px;
          display: block;
          max-height: 500px;
          transition: all 0.3s ease-out;
          p {
            color: #e2e2e2;
            font-family: 'Pretendard-Regular';
          }
        }
      }
    }
  }
}

@include tablet {
  #FAQ {
    .faq_title {
      .middle_header_img {
        width: 9%;
      }
    }
    .collapes_wrap {
      .faq_node {
        .q {
          width: 100%;
          .q_title {
            font-size: 18px;
          }
        }
      }
    }
  }
}
