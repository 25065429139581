$header_height: 90px;
$breakpoint-tablet: 758px;
$DEV: 0;
$aws_img: 758px;
$local_img: 758px;

@mixin animation($duration, $name) {
  animation-duration: $duration;
  animation-name: $name;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@mixin keyframe($animation_name) {
  @keyframes #{$animation_name} {
    @content;
  }
}

@mixin tablet {
  @media (min-width:#{$breakpoint-tablet}) {
    @content;
  }
}
