@import "../../../../style/_var.scss";

#middle_header {
  .middle_header_line {
    flex: 1;
    height: 1px;
    border: 1px solid rgba(113, 120, 181, 0.3);
    border-bottom: none;
  }
  .middle_header_img {
    margin: 0 20px;
  }
}
