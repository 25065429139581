@import "../../../style/_var.scss";

#road_map {
  margin-top: 50px;
  .h_road {
    .middle_header_img {
      width: 31%;
    }
  }
  // background-image: url("../../../../../public/img/road_map_back.png");
  background-image: url("https://indj.s3.ap-northeast-2.amazonaws.com/image/metabrox/metabx2/public/img/road_map_back.png");
  .road_map_back {
    padding: 0 20px;
  }

  .video {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .buttons {
      text-align: right;
      .button_img {
        width: 30px;
        margin-left: 5px;
      }
    }
  }
}

@include tablet {
  #road_map {
    margin-top: 170px;
    .h_road {
      .middle_header_img {
        width: 24%;
      }
    }
    .road_map_back {
    }
  }
}
