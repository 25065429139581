@import '../../../style/_var.scss';

footer {
  background-color: rgba(255, 255, 255, 0.1);
  .content {
    padding: 20px;
    .logo_text {
      width: 34px;
    }
    .information {
      margin: 20px 0;
      &_title {
        color: #f2f2f2;
        font-size: 14px;
      }
      &_text {
        color: #cdcdcd;
        font-size: 12px;
      }
    }
    .contacts {
      &_title {
        color: #f2f2f2;
        font-size: 14px;
      }
      &_text {
        color: #cdcdcd;
        font-size: 12px;
      }
    }
  }
}
@include tablet {
  footer {
    background-color: rgba(255, 255, 255, 0.1);
    .content {
      padding: 51px;
      max-width: none;
      display: flex;
      justify-content: center;
      .logo_text {
        width: 85px;
        height: 86px;
        margin-right: 112px;
      }
      .information {
        margin-top: 0px;
        margin-bottom: 20px;
        &_title {
          color: #f2f2f2;
          font-size: 22px;
          font-weight: bold;
        }
        &_text {
          color: #cdcdcd;
          font-size: 18px;
          font-family: 'Pretendard-Regular';
        }
      }
      .contacts {
        &_title {
          color: #f2f2f2;
          font-size: 22px;
          font-weight: bold;
        }
        &_text {
          color: #cdcdcd;
          font-size: 18px;
          font-family: 'Pretendard-Regular';
        }
      }
    }
  }
}
