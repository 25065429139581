@import '../../../../style/_var.scss';
.section {
  text-align: center;

  &.rtl {
    flex-direction: row-reverse;
  }

  &_img {
    margin: 0 auto;
    &.sub {
      margin-top: 87px;
      width: 120px;
    }

    &.phone_gift,
    &.desktop,
    &.phone {
      width: 100%;
    }
  }
  .fandom_lands {
    padding: 0px 50px;
  }
  .asset_notebook {
    padding: 0px 50px;
    margin-bottom: 74px;
  }
  .desc {
    margin-top: 30px;
    //줄바꿈 떄문에 잠시 주석
    // 마진 20씩 정도 피그마 다시확인하기
    padding: 0 40px;
    .section_title {
      color: #e2e2e2;
      font-size: 20px;
      &.main {
        font-size: 28px;
      }
    }
    .section_desc {
      white-space: pre-wrap;
      margin-top: 15px;
      color: #9d99b8;
    }
    .section_link {
      display: inline-flex;
      margin-top: 15px;

      &_apple {
        width: 22.59px;
        //height: 21.94px;
        margin-right: 15px;
        cursor: pointer;
      }
      &_google {
        width: 22.59px;
        margin-top: 3px;
        //height: 21.94px;
        margin-right: 15px;
        cursor: pointer;
      }
      &_media {
        width: 22.59px;
        //height: 21.94px;
        cursor: pointer;
      }
    }
  }
}

@include tablet {
  #Allstory {
    margin-top: 200px;
    .storyblop {
      position: relative;
      height: 96px;
    }
    .section_tablet {
      align-items: center;
    }
    .story {
      position: absolute;
      left: 50%;
      &_desc {
        display: flex;
        flex-direction: column;
        border: none;
        margin-top: 48px;
        .story_desc_node {
          margin-right: 290px;
          .story_title {
            font-size: 30px;
          }
          .story_con {
            font-size: 20px;
            line-height: 38px;
            color: #9d99b8;
          }
        }
      }
      span {
        &:nth-child(1) {
          font-size: 60px;
        }
        &:nth-child(2) {
          font-size: 30px;
        }
      }
    }
  }
  #whatToExpect {
    > div.section_node.false {
      border-top: 1px solid #00ff0000;
      border-image: linear-gradient(
        to right,
        #00ff0000 0%,
        #7178b5 50%,
        #00ff0000 100%
      );
      border-image-slice: 1;
    }
    .exception__text {
      font-size: 48px;
      font-weight: 700;
      line-height: 57.2px;
    }
    .exception_desc {
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      .exception_desc_node {
        display: flex;
        text-align: justify;
        .exception_title {
          font-size: 27px;
          width: 35%;
        }
        .exception_con {
          font-size: 22px;
          align-self: center;
          margin-top: 0px;
          width: 65%;
        }
      }
    }
  }
  .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_tablet {
      display: flex;
      text-align: left;
      padding-bottom: 50px;
      &_ship {
        display: flex;
        flex: 1 1 0;
      }
      .border_bottom {
        flex: 1 1 0;
      }
    }
    &_img {
      &.main {
        width: 50%;
        margin-top: 20px;
      }
      &.sub {
        width: 250px;
        margin-top: 70px;
      }
    }
    .desc {
      width: 50%;
      padding: 0px 20px;
      margin-top: 65px;

      .section_title {
        text-align: left;
        font-size: 30px;
        &.main {
          font-size: 30px;
        }
      }
      .section_desc {
        text-align: left;
        font-size: 20px;
      }
      .section_link {
        display: flex;
        margin-top: 15px;
        &_apple {
          width: 31.89px;
          height: 34.73px;
          margin-right: 26px;
        }
        &_google {
          width: 31.89px;
          height: 34.73px;
          margin-right: 26px;
        }
        &_media {
          width: 31.89px;
          height: 34.73px;
          margin-right: 26px;
        }
      }
    }
  }
}
