@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Black";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff")
    format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Medium";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff")
    format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard-Bold";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff")
    format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-ExtraBold";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff")
    format("woff");
  font-weight: 800;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap");

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
address,
img,
sub,
sup,
ul,
li,
fieldset,
form,
button,
label,
legend,
table,
footer,
header,
menu,
nav {
  color: white;
  font-family: "Pretendard-Medium";
}
.content {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}

.basic-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.start-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-align {
  justify-content: space-between;
  align-items: center;
}

img {
  max-width: none;
  width: 100%;
}

.black {
  font-family: "Pretendard-Black";
}
.regular {
  font-family: "Pretendard-Regular";
}
.bold {
  font-family: "Pretendard-Bold";
}

.section_bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
