@import "../style/_var.scss";

#wrap {
  overflow: hidden;
  // padding-top: $header_height;
  // background-image: url("../../../public/img/background.png");
  background-image: url("https://indj.s3.ap-northeast-2.amazonaws.com/image/metabrox/metabx2/background.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
