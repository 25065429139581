@import '../../../style/_var.scss';

#Allstory {
  text-align: center;
  margin-top: 75px;
  .story {
    span {
      font-family: 'Pretendard-Black';
      background: linear-gradient(
        to top,
        rgba(219, 238, 255, 0),
        rgba(219, 238, 255, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      font-size: 20px;
      &:nth-child(1) {
        font-size: 35px;
      }
      &:nth-child(2) {
        font-size: 24px;
      }
    }
  }
  .story_desc {
    .story_desc_node {
      margin-bottom: 100px;
      .story_title {
        font-family: 'Pretendard-ExtraBold';
        font-size: 28px;
        margin-bottom: 15px;
      }
      .story_con {
        line-height: 30px;
        color: #9d99b8;
        white-space: pre-wrap;
      }
    }
  }
  .border_bottom {
    border-bottom: 1px solid #00ff0000;
    border-image: linear-gradient(
      to right,
      #00ff0000 0%,
      #7178b5 50%,
      #00ff0000 100%
    );
    border-image-slice: 1;
  }
}

#whatToExpect {
  text-align: center;

  .border_bottom {
    border-bottom: 1px solid #00ff0000;
    border-image: linear-gradient(
      to right,
      #00ff0000 0%,
      #7178b5 50%,
      #00ff0000 100%
    );
    border-image-slice: 1;
  }
  .section_node {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    padding: 82px 0 80px 0;
    &.border_bottom {
      border-bottom: 1px solid #00ff0000;
      border-image: linear-gradient(
        to right,
        #00ff0000 0%,
        #7178b5 50%,
        #00ff0000 100%
      );
      border-image-slice: 1;
      margin-left: 1.25rem;
      margin-right: 1.25rem;
    }
  }
  .token {
    display: flex;
    justify-content: center;
    &__under {
      width: auto;
    }
  }
  .exception {
    margin: 92px 0px 70px;
    font-size: 28px;
    display: flex;
    justify-content: center;
    .exception__text {
      font-weight: 700;
    }
  }
  .exception_desc {
    margin-bottom: 100px;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    .exception_desc_node {
      margin-bottom: 62px;
      .exception_title {
        font-family: 'Pretendard-Bold';
        font-size: 22px;
        color: #e2e2e2;
      }
      .exception_con {
        line-height: 30px;
        color: #9d99b8;
        margin-top: 12px;
      }
    }
  }
}
