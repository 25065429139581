// @import "../../assets/css/break_point";
@import "../../../style/_var.scss";

.popup_back {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  .popup {
    position: fixed;
    top: 100px;
    width: 80%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10000;

    img {
      cursor: pointer;
    }

    .btns {
      margin-top: 20px;
      display: flex;
      width: 100%;
      .popup_button {
        all: unset;
        cursor: pointer;
      }

      & > *,
      & > .popup_button {
        display: block;
        width: 33.33%;
        text-align: center;
      }
    }
  }
}

@include tablet {
  .popup_back {
    .popup {
      width: 30%;

      img {
      }

      .btns {
        .popup_button {
        }

        & > *,
        & > .popup_button {
        }
      }
    }
  }
}

// @include desktop {
//   .popup_back {
//     .popup {
//       width: 30%;

//       img {
//       }

//       .btns {
//         .popup_button {
//         }

//         & > *,
//         & > .popup_button {
//         }
//       }
//     }
//   }
// }
