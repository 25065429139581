@import '../../../style/_var.scss';

#header {
  position: fixed;
  top: 0;
  background-color: #0d0731;
  width: 100%;
  height: 90px;
  z-index: 10000;
  padding: 0 20px;
  .content {
    .white_logo {
      width: 36px;
    }
    .drop_down_wrap {
      .drop_down {
        margin-left: 20px;
        .sns {
          width: 24px;
        }
        .lang {
          width: 24px;
        }
        .arrow_down {
          width: 11px;
          margin-left: 5px;
        }

        .sns_at {
          width: 20px;
          margin-right: 15px;
        }
      }
    }
  }
}

@include tablet {
  #header {
    .content {
      .white_logo {
        width: 48px;
      }
      .drop_down_wrap {
        .drop_down {
          .sns {
          }
          .lang {
          }
          .arrow_down {
          }
        }
      }
    }
  }
}
