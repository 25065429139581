@import "../../../style/_var.scss";

@include keyframe(move) {
  100% {
    transform: translateY(var(--size));
  }
  50% {
    transform: translateY(0px);
  }
  0% {
    transform: translateY(var(--size));
  }
}

#topPlanet {
  position: relative;
  margin-top: $header_height;
  .topPlanet {
    width: 200%;
    position: relative;
    left: -50%;
    margin-top: 150px;
    z-index: 100;
    --size: 20px;
    // @include animation(4s, move);
  }
  .bubble {
    position: absolute;
  }
  .bubble1 {
    width: 30.6%;
    top: 5%;
    left: -3%;
    --size: 10px;
    @include animation(6s, move);
  }
  .bubble2 {
    width: 15.5%;
    top: 10%;
    left: 80%;
    --size: 20px;
    @include animation(4s, move);
  }
  .bubble3 {
    width: 25.5%;
    top: 50%;
    left: -10%;
    z-index: 120;
    --size: 30px;
    @include animation(6s, move);
  }
  .bubble4 {
    width: 45.6%;
    top: 50%;
    left: 80%;
    z-index: 120;
    --size: 70px;
    @include animation(8s, move);
  }
  .bubble5 {
    width: 20.6%;
    top: 80%;
    left: 10%;
    --size: 10px;
    @include animation(3.4s, move);
  }
}

@include tablet {
  #topPlanet {
    .topPlanet {
      width: 100%;
      position: static;
    }
    .bubble {
    }
    .bubble1 {
    }
    .bubble2 {
    }
    .bubble3 {
    }
    .bubble4 {
    }
    .bubble5 {
    }
  }
}
